<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
      :style="{
        backgroundImage: `url(${backgroundImage})`
      }"
    >
      <!-- begin:: Content -->
      <h4
        class="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
        style="font-size: 32px;"
      >
       {{ $t('PUBLIC.NO_PAGE_ACCESS.HEADER') }}
      </h4>
      <p class="font-size-h3 font-weight-light">
        <p>
          {{ $t('PUBLIC.NO_PAGE_ACCESS.INFO_P') }}
        </p>
        <p>
          {{ $t('PUBLIC.NO_PAGE_ACCESS.INFO1') }}
        </p>
        <p>
          {{ $t('PUBLIC.NO_PAGE_ACCESS.INFO2') }}
        </p>

        <br/>
        <br/>
        <br/>
      </p>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<script>

import { PURGE_AUTH } from '@/core/services/store/auth.module';
import store from '@/core/services/store';

export default {
  name: "LackPagePermissions",
  data() {
    return {
      company_id: null
    }
  },
  mounted() {
    
  },
  computed: {
    
    backgroundImage() {
      return process.env.BASE_URL + "media/error/bg1.jpg";
    }
  }
};
</script>
